
import '../App.css';


function App() {
  return (
   <>
   <h1>
   <main class="containertext">
  <p>Hello 👋 I'm</p>
  <section class="animation">
    <div class="first"><div>Nitesh Kumar</div></div>
    <div class="second"><div>Web Developer</div></div>
    <div class="third"><div>Data Science Student</div></div>
  </section>
</main>
<div className='Name'>
  
</div>
</h1>
     <div class="container">
  <div class="carousel">
    <div class="carousel__face"><span></span></div>
    <div class="carousel__face"><span></span></div>
    <div class="carousel__face"><span></span></div>
    <div class="carousel__face"><span></span></div>
    <div class="carousel__face"><span></span></div>
    <div class="carousel__face"><span></span></div>
    <div class="carousel__face"><span></span></div>
    <div class="carousel__face"><span></span></div>
   
    
  </div>
  </div>
  
   </>
  );
}

export default App;
